import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
} from "styled-system";

import styled from "styled-components";

const Box = styled.div`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  opacity: ${(props) => {
    return props.opacity || "1";
  }};
  ${(props) => {
    if (props.transition) {
      return `transition: ${props.transition};`;
    }
  }}
  ${(props) => {
    if (props.pointerEvents) {
      return `pointer-events: ${props.pointerEvents};`;
    }
  }}
  ${(props) => {
    if (props.flipY) {
      return "transform: rotate(180deg);";
    }
  }}
  ${(props) => {
    if (props.transform) {
      return `transform: ${props.transform};`;
    }
  }}
`;

export default Box;
