import Box from "../components/Box";

export default function Custom404() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
    >
      {" "}
      <h1>404 - Page Not Found</h1>
    </Box>
  );
}
